@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --dark: 0 0% 65%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --primary: 327 100% 45%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --footer: 0 0% 75%;
    --socket: 0 0% 55%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 10% 3.9%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;

    --chart-2: 173 58% 39%;

    --chart-3: 197 37% 24%;

    --chart-4: 43 74% 66%;

    --chart-5: 27 87% 67%;

    --success: 89 50% 50%;

    --pink: 327, 100%, 45%;
    --green: 89, 50%, 50%;
    --blue: 198, 100%, 44%;
  }
}

h1 {
  @apply text-5xl font-bold md:text-6xl;
}

h2 {
  @apply text-3xl font-bold sm:text-4xl md:text-5xl;
}

h3 {
  @apply text-2xl font-bold leading-10 sm:text-3xl md:text-[1.875rem];
}

/* select {
  @apply px-10 py-4 focus:outline-none;
} */

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.cs-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.cs-button {
  clip-path: polygon(9% 0%, 100% 0%, 91% 100%, 0% 100%);
}

.shape {
  width: 120px;
  height: 350px;
  background-color: black;
  position: relative;
}

.shape:before {
  content: "";
  width: 398px;
  height: 38px;
  background: #00c000;
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
}

.shape,
.shape:before {
  -webkit-clip-path: polygon(98% 0, 100% 0, 2% 100%, 0 100%, 0 100%);
  clip-path: polygon(98% 0, 100% 0, 2% 100%, 0 100%, 0 100%);
}

.button-long {
  width: 100%;
  display: flex;
  place-content: center;
  z-index: 0;
  padding: 8px 20px;
  color: #fff;
}

.button-long::before {
  content: "";
  position: absolute;
  top: 0;
  /* left: 10px; */
  width: 100%;
  height: 100%;
  background-color: black;
  transform: skewX(-15deg);
  z-index: -1;
}

/* .button-long::after {
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  width: 100%;
  height: 100%;
  background-color: inherit;
  transform: skewX(15deg);
  z-index: -1;
} */

.no-strip {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #e6007e;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.7s;
}

.strip {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #e6007e;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.7s;
}

.button-long:hover .strip {
  width: calc(100% + 2px);
}

.no-strip-1 {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #009ee0;
}

.strip-1 {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #009ee0;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.7s;
}

.button-long:hover .strip-1 {
  width: calc(100% + 2px);
}

.no-strip-2 {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #82bf40;
}

.strip-2 {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #82bf40;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.7s;
}

.button-long:hover .strip-2 {
  width: calc(100% + 2px);
}

.no-strip-3 {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #e6007e;
}

.strip-3 {
  position: absolute;
  top: 0;
  left: -1px;
  width: 10px;
  height: 100%;
  z-index: -1;
  transform: skewX(-15deg);
  background-color: #e6007e;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.7s;
}

.button-long:hover .strip-3 {
  width: calc(100% + 2px);
}

.burger path {
  transition: fill 200ms;
}

.burger:hover path {
  fill: #e6007e;
}

.cpp-overlay {
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
}

.cpp-link-item {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

select {
  padding: 5px 50px 5px 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.campus-banner-clip-path {
  clip-path: polygon(102px 0%, 100% 0%, 100% 100%, 20px 100%);
}

@media (min-width: 640px) {
  .campus-banner-clip-path {
    clip-path: polygon(122px 0%, 100% 0%, 100% 100%, 42px 100%);
  }
}

@media (min-width: 768px) {
  .campus-banner-clip-path {
    clip-path: polygon(215px 0%, 100% 0%, 100% 100%, 100px 100%);
  }
}

.rdp-weekdays {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.rdp-week {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.rdp-month_grid {
  width: 100%;
}

@media (min-width: 850px) {
  .calendar-clip-path {
    clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
  }
}

/* .team-modal-portrait {
  clip-path: polygon(89px 0%, 100% 0%, 100% 100%, 0% 100%);
}

@media all and (min-width: 1024px) {
  .team-modal-portrait {
    clip-path: polygon(89px 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.team-modal-content {
  clip-path: polygon(115px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 278px);
}

@media all and (max-width: 639px) {
  .team-modal-content {
    clip-path: polygon(90px 0%, 100% 0%, 100% 100%, 0% 100%, 0% 222px);
  }
} */
